<template>
  <el-row type="flex" justify="center" class="bg">
    <el-col>Logout</el-col>
  </el-row>
</template>

<script>
import {mapActions} from "vuex";

export default {
  computed: {
  },
  methods: {
    ...mapActions(["logout"]),
  },
  async created() {
    await this.logout()
    await this.$router.push({name: "login"});


  }
}
</script>

<style scoped>



</style>
